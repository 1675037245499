/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // if(!Modernizr.input.placeholder){

        //     $('[placeholder]').focus(function() {
        //       var input = $(this);
        //       if (input.val() === input.attr('placeholder')) {
        //         input.val('');
        //         input.removeClass('placeholder');
        //       }
        //     }).blur(function() {
        //       var input = $(this);
        //       if (input.val() === '' || input.val() === input.attr('placeholder')) {
        //         input.addClass('placeholder');
        //         input.val(input.attr('placeholder'));
        //       }
        //     }).blur();
        //     $('[placeholder]').parents('form').submit(function() {
        //       $(this).find('[placeholder]').each(function() {
        //         var input = $(this);
        //         if (input.val() === input.attr('placeholder')) {
        //           //input.val('');
        //         }
        //       });
        //     });

        // }

        // if (!Modernizr.svg) {
        //     var imgs = document.getElementsByTagName('img');
        //     for (var i = 0; i < imgs.length; i++) {
        //         if (/.*\.svg$/.test(imgs[i].src)) {
        //             imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
        //         }
        //     }
        // }

        // $('input, textarea').placeholder();

        // $(document).ready(function() {

        //     $('#kontaktform').bootstrapValidator({
        //         //submitButtons: ".nej",
        //         feedbackIcons: {
        //             valid: 'glyphicon glyphicon-ok',
        //             invalid: 'glyphicon glyphicon-remove',
        //             validating: 'glyphicon glyphicon-refresh'
        //         },
        //         fields: {
        //             lastname: {
        //                 feedbackIcons: true,
        //                 validators: {
        //                     notEmpty: {
        //                         message: 'Skriv in ditt efternamn',
        //                     },
        //                 }
        //             },

        //             forename: {
        //                 feedbackIcons: true,
        //                 validators: {
        //                     notEmpty: {
        //                         message: 'Skriv in ditt förnamn',
        //                     },
        //                 }
        //             },
        //             personnr: {
        //                 feedbackIcons: true,
        //                 validators: {
        //                     notEmpty: {
        //                         message: 'Skriv in ditt personnummer',
        //                     },
        //                 }
        //             },
        //             email: {
        //                 feedbackIcons: true,
        //                 validators: {
        //                     notEmpty: {
        //                         message: 'Skriv in din E-post',
        //                     },
        //                     emailAddress: {
        //                         message: 'Fyll i en korrekt E-post adress'
        //                     }
        //                 }
        //             },
                 
        //           }
        //     });

               
        // });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
		$(window).load(function() {
	        $('.bxslider').bxSlider({
		        mode: 'fade',
		        captions: false,
		        pager: false,
		        controls: true,
		        nextText: '<i class="fa fa-angle-right fa-5x"></i>',
		        prevText: '<i class="fa fa-angle-left fa-5x"></i>',
		        auto: true,
		        pause: 5000,
		        speed: 700,
		        onSliderLoad: function(){
		            $(".bxslider").css("visibility", "visible");

		            
	          	},

		        onSlideBefore: function ($slideElement, oldIndex, newIndex) {
		            
		            $('.active-slide').removeClass('active-slide');
		            
		        },

		        onSlideAfter: function ($slideElement, oldIndex, newIndex) {
		            $('.bxslider>li').eq(newIndex).addClass('active-slide');
		        },
	      
	        });
      	});

      

      	$(".scroll-down .fa").click(function() {
      	    $('html, body').animate({
      	        scrollTop: $(".wrap").offset().top
      	    }, 1000);
      	});

		function initialize() {

			var styles = [
			  {
			    "featureType": "landscape",
			    "stylers": [
			      { "weight": 0.1 },
			      { "saturation": -100 },
			      { "lightness": 100 },
			      { "gamma": 1.8 },
			      { "color": "#eee" }
			    ]
			  },{
			    "featureType": "road",
			    "stylers": [
			      { "color": "#807f80" },
			      { "saturation": -100 },
			      { "lightness": 100 }
			    ]
			  },{
			    "featureType": "water",
			    "stylers": [
			      { "saturation": -100 },
			      { "lightness": -17 }
			    ]
			  },{
			      "featureType": "poi",
			      "elementType": "geometry",
			      "stylers": [
			        { "color": "#808080" },
			        { "saturation": -70 },
			        { "lightness": 45 }
			      ]
			    }
			];

			var myLatLng = new google.maps.LatLng(59.3148573, 18.0764729);
			var mapCanvas = document.getElementById('map-canvas');
			var mapOptions = {
				center: myLatLng,
				styles: styles,
				zoom: 13,
				mapTypeId: google.maps.MapTypeId.ROADMAP
			};
			
			var map = new google.maps.Map(mapCanvas, mapOptions);
			var marker = new google.maps.Marker({
				position: myLatLng,
			  	title:"Bara Bistro Bar", 
			  	url: 'http://goo.gl/OBLtIi'
			});

			// To add the marker to the map, call setMap();
			marker.setMap(map);
			google.maps.event.addListener(marker, 'click', function() {
				window.open(
				  this.url,
				  '_blank' // <- This is what makes it open in a new window.
				);

				console.log(this);

			});
		}
		google.maps.event.addDomListener(window, 'load', initialize); 

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    //Boka
    'boka_bord': {
      init: function() {
        // JavaScript to be fired on the about us page

        $.fn.datepicker.dates['sv'] = {
        	days: ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag", "Söndag"],
        	daysShort: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör", "Sön"],
        	daysMin: ["Sö", "Må", "Ti", "On", "To", "Fr", "Lö", "Sö"],
        	months: ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"],
        	monthsShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
        	today: "Idag",
        	format: "yyyy-mm-dd",
        	weekStart: 1,
        	clear: "Rensa"
        };
        
        $('.datepicker').datepicker({
    		language: 'sv'
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
